import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const socials = {
	facebook: {
		icon: <FontAwesomeIcon icon="fa-brands fa-facebook-f" />,
		url: (url) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
	},
	whatsapp: {
		icon: <FontAwesomeIcon icon="fa-brands fa-whatsapp" />,
		url: (url) => `https://wa.me/?text=${url}`
	},
	email: {
		icon: <FontAwesomeIcon icon="envelope" />,
		url: (url, title) => `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`
	}
};


export default function Share({ title }) {
	const [domain, setDomain] = useState('');
	useEffect(() => setDomain(window.location.hostname), []);
	
	const location = useLocation(),
			url = `https://${domain + location.pathname}`;

	const handleClick = social => window.open(socials[social].url(url, title), "_blank");

	return (
		<ul className="social-share">
			<li>Condividi:</li>
			{Object.keys(socials).map(social =>
				<li key={social} onClick={() => handleClick(social)}>
					{socials[social].icon}
				</li>
			)}
		</ul>
	);
}