import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { carsList, services } from '../data';
import HeroSlider from "../components/HeroSlider";
import Product from "../components/Product";
import BookingCTA from "../components/BookingCTA";
import BookingForm from "../components/BookingForm";

import ferrariSpider from '../imgs/ferrari-spider.webp';
import golf from '../imgs/golf.webp';
import bg from '../imgs/bg.webp';
import sedili from '../imgs/sedili.webp';
import ristrutturazione from '../imgs/ristrutturazione.jpg';
import vendita from '../imgs/vendita.jpg';
import motore from '../imgs/motore.jpg';
import puliziaSedile from '../imgs/pulizia-sedile.webp';


export default function Home() {
	return (
		<>
			<Helmet>
				<title>Autolavaggio Tellatin Ezio - Autolavaggio e Rivendita auto</title>
				<meta name="description" content="Le nostre specializzazioni sono il lavaggio di tappezzerie, motori e sottoscocche. Inoltre se cerchi un'auto usata scopri le migliori offerte sulle nostre auto usate in pronta consegna." />
				<meta name="keywords" content="Autolavaggio Tellatin Ezio, autolavaggio, Tellatin Ezio, verniciatura pelli, sottoscocche, auto alluvionate, auto danneggiate, auto usate pronta consegna, asciugatura, tappezzeria auto, auto, carrozzeria, perdite di olio, lavaggi motore, lavaggio auto, ambiente, ecosostenibile,servizi autolavaggio, vendita auto, auto usate" />
			</Helmet>

			<HeroSlider />
			
			<section className="p-bk-md bg-dark">
				<div className="container services-grid relative">
					<div className="service-cta flex">
						<Link to="vendita">
							<img src={vendita} alt="Vendita auto usate - Autolavaggio Tellatin Ezio" />
						</Link>
						<div>
							<Link to="vendita">Vendita auto <FontAwesomeIcon icon="arrow-right" className="m-0" /></Link>
							<p className="txt-light">Vendita auto usate plurimarca.</p>
						</div>
					</div>

					<div className="service-cta flex">
						<Link to="servizi/ristrutturazione-interni">
							<img src={ristrutturazione} alt="Ristrotturazione interni auto - Autolavaggio Tellatin Ezio" />
						</Link>
						<div>
							<Link to="servizi/ristrutturazione-interni">Ristrutturazione interni <FontAwesomeIcon icon="arrow-right" className="m-0" /></Link>
							<p className="txt-light">Ripristino spalline sedili moquette, cruscotti, pannelli porta e molto altro.</p>
						</div>
					</div>
					
					<div className="service-cta flex">
						<Link to="servizi/lavaggio-motore">
							<img src={motore} alt="Lavaggio motore auto - Autolavaggio Tellatin Ezio" />
						</Link>
						<div>
							<Link to="servizi/lavaggio-motore">Lavaggio motore <FontAwesomeIcon icon="arrow-right" className="m-0" /></Link>
							<p className="txt-light">Puliamo i motori in tutte le sue parti.</p>
						</div>
					</div>
				</div>
			</section>
			
			
			<section className="container pb-2">
				<div className="grid gtc-1-2 g-sm">
					<div>
						<h2><span className="txt-primary">Ferrari 360</span> SPIDER F1.</h2>
						<p>Scopri le migliori offerte sulle nostre auto usate in pronta consegna.</p>
						<Link to="/vendita" className="btn mt-2">Vedi vendita <FontAwesomeIcon icon="arrow-right" /></Link>
					</div>
						
					<img src={ferrariSpider} className="md-screen" alt="Ferrari 360 SPIDER F1 - Vendita auto Tellatin Ezio" />
				</div>
			</section>

			<section className="container grid gtc-3">
				{carsList.slice(0, 3).map(car =>
					<Product
						key={car.slug}
						name={car.name}
						slug={car.slug}
						img={car.imgs[0]}
						price={car.price}
                  year={car.anno}
                  km={car.chilometraggio}
                  changeType={car.tipoCambio}
					/>
				)}
			</section>

			<section className="pb-0 bg-img" style={{"--imgUrl": `url(${bg})`}}>
				<div className="container txt-center">
					<h5 className="mx-w-25 m-in-auto"><em>Sei alla ricerca di un'auto usata? Scegli fra i nostri modelli.</em></h5>
					<h2 className="fs-lg"><span className="txt-primary">Volkswagen</span><br />Golf 1600 GL Cabriolet</h2>
					
					<img src={golf} className="mt-3 mb--2 mb-screen--7" alt="Volkswagen Golf 1600 GL Cabriolet - Vendita auto Tellatin Ezio" />
				</div>
			</section>

			<section className="bg-dark pb-screen-0">
				<div className="container grid g-lg mt-screen-3">
					<img src={sedili} className="translateY md-screen" alt="Autolavaggio Tellatin Ezio Servizi" />

					<div>
						<h2 className="txt-white mb-3">Autolavaggio <span className="txt-primary">Tellatin Ezio</span><br />una garanzia!</h2>
						<ul className="services-grid">

							{services.map(service => 
								<li className="service" key={service.name}>
									<FontAwesomeIcon icon={service.icon} />
									<p>{service.name}</p>
								</li>
							)}
							
						</ul>
					</div>
				</div>
			</section>

			<section className="container grid g-lg">
				<div className="mt-screen-3">
					<h2>Richiedi <span className="txt-primary">informazioni</span></h2>
					<p>Compila il form per avere tutte le informazioni sull'auto che hai scelto e prenota un appuntamento per vederla di persona. Saremo lieti di risponderti al più presto.</p>
					
					<div className="p-bk-md">
						<BookingCTA />
					</div>
				</div>

				<BookingForm />
			</section>

			<section className="p-big relative parallax bg-img" style={{"--imgUrl":  `url(${puliziaSedile})` }}>
				<div className="overlay overlay-dark"></div>
				<div className="container relative txt-center">
					<h1 className="fs-md txt-white">Cosa <span className="txt-primary">facciamo</span></h1>
					<h5 className="mx-w-25 m-in-auto mb-3 txt-white"><em>Le nostre specializzazioni:</em></h5>
					<ul className="list txt-white">
						<li>&bull; Lavaggio di tappezzerie (auto, divani, tappeti)</li>
						<li>&bull; Motori, sottoscocche (con ponte sollevatore)</li>
						<li>&bull; Lavaggi manuali serviti</li>
						<li>&bull; Ripristino e verniciatura pelli</li>
					</ul>
					
					<Link to="servizi" className="btn mt-3">Tutti i servizi <FontAwesomeIcon icon="arrow-right" /></Link>
				</div>
			</section>

		</>
	);
}