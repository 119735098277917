import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookingForm from "../components/BookingForm";
import { Helmet } from 'react-helmet';
import PageTitle from '../components/PageTitle';

export default function Contatti() {
   return (
      <>
         <Helmet>
            <title>Contatti - Autolavaggio Tellatin Ezio</title>
            <meta name="description" content="Richiedi informazioni: compila il form o vieni a trovarci in autolavaggio in Via Canova Antonio 39, 36027 - Rosà (VI). Email: ezio.tellatin@gmail.com -  Tel: 0424 580568" />
            <meta name="keywords" content="Autolavaggio Tellatin Ezio, autolavaggio, Tellatin Ezio, servizi autolavaggio, vendita auto, auto usate" />
         </Helmet>

         <PageTitle pageName="Contatti" />

         <section className="container">
            <div className="grid g-lg place-center mb-3">
               <div>
                  <h2>Richiedi <span className="txt-primary">informazioni</span></h2>
                  <BookingForm />
               </div>

               <div>
                  <div className="flex align-center mb-3">
                     <FontAwesomeIcon icon="phone" className="scale-2" />
                     <span>
                        <h4 className="m-0">Telefono</h4>
                        <h3 className="m-0">
                           <a href="tel:0424580568">0424 580568</a>
                        </h3>
                     </span>
                  </div>
                  <div className="flex align-center mb-3">
                     <FontAwesomeIcon icon="envelope" className="scale-2" />
                     <span>
                        <h4 className="m-0">Email</h4>
                        <h3 className="m-0">
                           <a href="mailto:ezio.tellatin@gmail.com">ezio.tellatin@gmail.com</a>
                        </h3>
                     </span>
                  </div>
                  <div className="flex align-center mb-3">
                     <FontAwesomeIcon icon="home" className="scale-2" />
                     <span>
                        <h4 className="m-0">Indirizzo</h4>
                        <h3 className="m-0">Via Canova Antonio 39, 36027 - Rosà (VI)</h3>
                     </span>
                  </div>
                  <div className="flex align-center">
                     <FontAwesomeIcon icon="clock" className="scale-2" />
                     <span>
                        <h4 className="m-0">Orari <br/><small>Si riceve preferibilmente su appuntamento.</small></h4>
                        <h3 className="m-0">Lun-Ven: 7:00 - 12:00 | 14:00 - 19:00<br />Sab: 7:00 - 12:00</h3>
                        <h3 className="m-0">Chiuso per Ferie dal 12 al 27 Agosto.</h3>
                     </span>
                  </div>
               </div>
            </div>
            
            <iframe width="100%" height="500" title="Autolavaggio Tellatin Ezio" src="https://www.google.com/maps?q=Autolavaggio+Tellatin+Ezio&t=&z=15&ie=UTF8&iwloc=&output=embed"></iframe>
         </section>
      </>
   );
}