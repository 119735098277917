import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { servicesList } from '../data';
import PageTitle from '../components/PageTitle';

export default function Servizi() {
   return (
      <>
         <Helmet>
            <title>Servizi - Autolavaggio Tellatin Ezio</title>
            <meta name="description" content="Scopri tutti i nostri servizi: lavaggio sottoscocca, lavaggio auto storiche, lavaggio moto storiche, lavaggio motore, asciugatura auto alluvionate, trattamento all'ozono..." />
            <meta name="keywords" content="lavaggio sottoscocca, lavaggio auto storiche, lavaggio moto storiche, lavaggio motore, asciugatura auto alluvionate, trattamento all'ozono, verniciatura pelli, pulitura sedili, pulitura auto divani poltrone e sedie, ristrutturazione interni auto, sanificazione auto, servizi, servizi autolavaggio, Autolavaggio Tellatin Ezio, autolavaggio, Tellatin Ezio, servizi autolavaggio, vendita auto, auto usate" />
         </Helmet>

         <PageTitle pageName="Servizi" />

         <section className="container grid gtc-3">
            {servicesList.map(service =>
               <article className="service-thumb" key={service.slug}>
                  <Link to={service.slug}>
                     <img src={service.img} alt={service.name + 'Servizi Autolavaggio Tellatin Ezio'} />
                  </Link>
                  <div className="thumb-description">
                     <Link to={service.slug}>
                        <h3>{service.name}</h3>
                     </Link>
                  </div>
               </article>
            )}
         </section>
      </>
   );
}