import { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { carsList } from '../data';
import  emailjs from '@emailjs/browser';


export default function BookingForm() {
   const form = useRef();
   const initValue = { name:'', email: '', phone: '', car: '', message:'' };
   const [formVals, setFormVals] = useState(initValue),
         [formErrs, setFormErrs] = useState({}),
         [isSubmit, setIsSubmit] = useState(false),
         [isSent, setIsSent] = useState(false);


   const handleChange = e => {
      const { id, value } = e.target;
      setFormVals({ ...formVals, [id]: value });
   };
   
   const handleSubmit = e => {
      e.preventDefault();
      setFormErrs(validate(formVals));
      setIsSubmit(true);
   }

   useEffect(() => {
      if(Object.keys(formErrs).length === 0 && isSubmit) {
         emailjs.sendForm('service_prmjtff', 'template_k5jj6vh', form.current, 'KgdDc7PQsK2_NtYJv')
            .then(result => {
               console.log(result.text);
               setFormVals(initValue);
               setIsSent(true);
               setFormErrs({});
               setIsSubmit(false);
            }, error => {
               console.log(error.text);
               setIsSubmit(false);
               setIsSent(false);
            }
         );
      }
   }, [formErrs, isSubmit]);
   
   useEffect(() => {
		if(isSent) {
			const msgSent = setTimeout(()=>setIsSent(false), 5000);
			return () => clearTimeout(msgSent);
		}
	}, [isSent]);

   const validate = (vals) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
            phoneRegex= /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      
      if (!vals.name) {
         errors.name = 'Il nome è obbligatorio!';
      } else if (vals.name.length < 4) {
         errors.name = 'Inserisci almeno 4 caratteri!';
      }

      if (!vals.email) {
         errors.email = 'L\'email è obbligatoria!';
      } else if (!emailRegex.test(vals.email)) {
         errors.email = 'Questo non è un formato email valido!';
      }

      if (!vals.phone) {
         errors.phone = 'Il telefono è obbligatorio!';
      } else if (!phoneRegex.test(vals.phone)) {
         errors.phone = 'Questo non è un formato valido!';
      }
      
      return errors;
   };

   return (
      <form className="bg-light p-2" ref={form} onSubmit={handleSubmit}>
         <label htmlFor="name">
            <FontAwesomeIcon icon="user-friends" />
            <span className="sr-only">Nome Cognome</span>
         </label>
         <input
            type="text"
            name="name"
            id="name"
            placeholder="Nome Cognome"
            required 
            value={formVals.name}
            onChange={handleChange}
         />
         {formErrs.name &&
            <h4 className="txt-danger">{formErrs.name}</h4>
         }

         <label htmlFor="email">
            <FontAwesomeIcon icon="envelope" />
            <span className="sr-only">Email</span>
         </label>
         <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            required 
            value={formVals.email}
            onChange={handleChange}
         />
         {formErrs.email &&
            <h4 className="txt-danger">{formErrs.email}</h4>
         }

         <label htmlFor="phone">
            <FontAwesomeIcon icon="phone" />
            <span className="sr-only">Telefono</span>
         </label>
         <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="Telefono"
            required 
            value={formVals.phone}
            onChange={handleChange}
         />
         {formErrs.phone &&
            <h4 className="txt-danger">{formErrs.phone}</h4>
         }

         <label htmlFor="car">
            <FontAwesomeIcon icon="car" />
            <span className="sr-only">Scegli macchina</span>
         </label>
         <select
            name="car"
            id="car"
            value={formVals.car}
            onChange={handleChange}
         >
            <option defaultValue>- Seleziona auto -</option>
            {carsList.map(car =>
               <option key={car.slug} value={car.name}>{car.name}</option>
            )}
         </select>

         <label htmlFor="message">
            <FontAwesomeIcon icon="message" />
            <span className="sr-only">Messaggio</span>
         </label>
         <textarea
            name="message"
            id="message"
            rows="5"
            placeholder="Messaggio"
            value={formVals.message}
            onChange={handleChange}
         />

         {isSent &&
            <h3 className="txt-success">Messaggio inviato!</h3>
         }
         {Object.keys(formErrs).length !== 0 &&
            <h3 className="txt-danger">Errore! Controlla i campi.</h3>
         }

         <button type="submit" disabled={isSubmit ? true : false}>Invia ora</button>
      </form>
   );
}