import { Helmet } from 'react-helmet';

import useScript from '../hooks/useScript';
import PageTitle from '../components/PageTitle';

export default function ChiSiamo() {
   useScript('');
   return (
      <>
         <Helmet>
            <title>Cookie policy - Autolavaggio Tellatin Ezio</title>
         </Helmet>

         <PageTitle pageName="Cookie policy" />

         <section className="container">
            <h1>Cookie Policy</h1>
            
            <div id="CookieDeclaration" />
         </section>
      </>
   );
}