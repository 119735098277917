import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function ProductBig({ name, slug, img, price,year, km, changeType }) {
   return (
      <article className="grid big-pricing">
         <Link to={'/vendita/' + slug}>
            <img src={img} alt={name + " - Vendita auto - Autolavaggio Tellatin Ezio"} />
         </Link>

         <div>
            <h2>
               <Link to={'/vendita/' + slug}>{name}</Link>
            </h2>
            {typeof price === 'number' ?
               <h1 className="txt-primary fs-lg"><sup>€</sup> {price}</h1>
               :
               <h1 className="txt-primary">{price}</h1>
            }
            <ul className="grid g-1 mt-2">
               <li className="pricing-feature">
                  <FontAwesomeIcon icon="calendar" />
                  <p className="m-0">Anno</p>
                  <p>{year}</p>
               </li>
               <li className="pricing-feature">
                  <FontAwesomeIcon icon="road" />
                  <p className="m-0">Chilometraggio</p>
                  <p>{km}</p>
               </li>
               <li className="pricing-feature">
                  <FontAwesomeIcon icon="sitemap" />
                  <p className="m-0">Tipo di cambio</p>
                  <p>{changeType}</p>
               </li>
            </ul>

            <Link to={'/vendita/' + slug} className="btn w-100">Dettagli <FontAwesomeIcon icon="arrow-right" /></Link>
         </div>
      </article>
   );
}