import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageTitle from '../components/PageTitle';

export default function NotFound() {
   return (
      <>
         <Helmet>
            <title>Pagina Non Trovata - Autolavaggio Tellatin Ezio</title>
         </Helmet>

         <PageTitle pageName="Pagina non trovata" />

         <section className="container txt-center">
            <h4 className="fs-lg">404</h4>
            <h2>Pagina non trovata</h2>
            <p>La pagina che stai cercando non esiste.</p>

            <Link to="/" className="btn">Torna all'home</Link>
         </section>
      </>
   );
}