import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../imgs/logo.svg';

function Footer() {
	const dateYear = new Date().getFullYear();
	return (
		<footer>
			<div className="container">
				
				<Link to="/">	
					<img src={logo} alt="Autolavaggio Tellatin Ezio logo" />
				</Link>
				
				<ul className="grid gtc-3 p-bk-md">
					<li>
						<FontAwesomeIcon icon="phone" />
						<p><a href="tel:0424580568">+39 0424 580568</a></p>
					</li>
					
					<li>
						<FontAwesomeIcon icon="envelope" />
						<p><a href="mailto:ezio.tellatin@gmail.com">ezio.tellatin@gmail.com</a></p>
					</li>
					
					<li>
						<FontAwesomeIcon icon="map-marker-alt" />
						<a href="https://www.google.com/maps/place/Autolavaggio+Tellatin+Ezio/@45.7281033,11.7355221,15z/data=!4m2!3m1!1s0x0:0x177d001542bd5fba?sa=X&ved=2ahUKEwimhp2s_O_9AhVzSfEDHfFBB80Q_BJ6BAhPEAg" target="_blank" rel="noreferrer">
							<p>Via Canova Antonio 39</p>
							<p>36027 - Rosà (VI)</p>
						</a>
					</li>
				</ul>
			</div>

			<div className="copyright">
				<div className="container">
					<p>&copy;  Tellatin Ezio {dateYear} - P.Iva: 02715810244.<br />Developed by <a href="https://milanesieditore.it" target="_blank" rel="noreferrer">Milanesi Editore</a>.</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;