export const services = [
   {
      name: 'Lavaggio sottoscocca',
      icon: 'shower'
   },
   {
      name: 'Verniciatura pelli',
      icon: 'brush'
   },
   {
      name: 'Trattamento all\'ozono',
      icon: 'wind'
   },
   {
      name: 'Ristrutturazione interni',
      icon: 'chair'
   },
   {
      name: 'Pulitura sedili',
      icon: 'broom'
   },
   {
      name: 'Sanificazione',
      icon: 'shield-virus'
   },
];


export const servicesList = [
   {
      img: require('./imgs/servizi/sottoscocca.jpg'),
      name: 'Lavaggio sottoscocca',
      slug: 'lavaggio-sottoscocca',
      content: 'Per il lavaggio sottoscocca a Bassano nel Grappa, rivolgiti a Tellatin Ezio! Un autolavaggio di oltre 1600 mq, con macchine sollevatrici capaci di alzare auto sino a 3000 kg. Per conoscere tutti i servizi che offriamo, chiamaci, saremo lieti di accogliere la tua richiesta!',
      list: [
         'Trattamento all\'Ozono',
         'Pulitura Tappezzerie',
         'Ripristino Pelli'
      ],
      imgs: [
         require('./imgs/servizi/gallery/sottoscocca-1.jpg'),
         require('./imgs/servizi/gallery/sottoscocca-2.jpg'),
         require('./imgs/servizi/gallery/sottoscocca-3.jpg'),
         require('./imgs/servizi/gallery/sottoscocca-4.jpg'),
         require('./imgs/servizi/gallery/sottoscocca-5.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/auto-storiche.jpg'),
      name: 'Lavaggio auto storiche',
      slug: 'lavaggio-auto-storiche',
      content: 'Se preferisci il lavaggio manuale per le auto storiche e sei alla ricerca di un autolavaggio a Bassano del Grappa, affidati a Tellatin Ezio. Potrai contare su un servizio dall’ottimo rapporto qualità/prezzo. La tua auto storica sarà in ottime mani!',
      list: [
         'Ripristino Pelli Auto',
         'Lavaggio Sotto Scocca',
         'Pulitura Tappezzerie',
         'Lavaggio Motori'
      ],
      imgs: [
         require('./imgs/servizi/gallery/interni-auto-d\'epoca.jpg'),
         require('./imgs/servizi/gallery/auto-d\'epoca.jpg'),
         require('./imgs/servizi/gallery/auto-d\'epoca-fronte.jpg'),
         require('./imgs/servizi/gallery/auto-d\'epoca-retro.jpg'),
         require('./imgs/servizi/gallery/auto.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/moto-storiche.jpg'),
      name: 'Lavaggio moto storiche',
      slug: 'lavaggio-moto-storiche',
      content: 'Per il lavaggio manuale di moto storiche a Bassano del Grappo, rivolgiti e scegli Tellatin Ezio. Presso il suo autolavaggio sono molteplici i servizi offerti, tutti hanno in comune l’ottimo rapporto qualità/prezzo. Affida la tua moto storica a mani esperte! Per maggiori informazioni chiama o se preferisci vieni in sede.',
      list: [
         'Lavaggio Auto Storiche',
         'Lavaggio Sotto Scocca',
         'Pulitura Tappezzerie',
         'Lavaggio Motori'
      ],
      imgs: [
         require('./imgs/servizi/gallery/moto-cross.jpg'),
         require('./imgs/servizi/gallery/moto-storica.jpg'),
         require('./imgs/servizi/gallery/moto-storica-serbatoio.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/lavaggio-motore.jpg'),
      name: 'Lavaggio motore',
      slug: 'lavaggio-motore',
      content: 'Sei alla ricerca di un\'azienda specializzata nei lavaggi di motori a Bassano del Grappa? La tua ricerca può terminare! Tellatin Ezio presso il suo autolavaggio offre molteplici servizi per auto e moto, per conoscerli tutti chiama!',
      list: [
         'Trattamento all\'Ozono',
         'Lavaggio Sottoscocca',
         'Pulitura Tappezzerie',
         'Ripristino Pelli'
      ],
      imgs: [
         require('./imgs/servizi/gallery/lavaggio-motore-1.jpg'),
         require('./imgs/servizi/gallery/lavaggio-motore-2.jpg'),
         require('./imgs/servizi/gallery/lavaggio-motore-3.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/auto-alluvionate.jpg'),
      name: 'Asciugatura auto alluvionate',
      slug: 'asciugatura-auto-alluvionate',
      content: 'Sei alla ricerca di un’azienda specializzata nell\'asciugatura d\'auto alluvionate a Bassano del Grappa? La tua ricerca può terminare! Tellatin Ezio presso il suo autolavaggio offre molteplici servizi per auto e moto, per conoscerli tutti chiama ora!',
      list: [
         'Lavaggio Auto Storiche',
         'Lavaggio Moto Storiche',
         'Pulitura Tappezzerie',
         'Ripristino Pelli'
      ]
   },
   {
      img: require('./imgs/servizi/trattamento-ozono.jpg'),
      name: 'Trattamento all\'ozono',
      slug: 'trattamento-ozono',
      content: 'Sei alla ricerca di un centro specializzato in trattamenti ozono per auto a Bassano del Grappa? Sei sulla pagina giusta. Chiama subito per avere maggiori informazioni e richiedere un preventivo.',
      list: [
         'Ripristino Pelli Auto',
         'Lavaggio Sotto Scocca',
         'Pulitura Tappezzerie',
         'Lavaggio Motori'
      ]
   },
   {
      img: require('./imgs/servizi/verniciatura-pelli.jpg'),
      name: 'Verniciatura pelli',
      slug: 'verniciatura-pelli',
      content: 'Per il ripristino e la verniciatura delle pelli a Bassano del Grappa, rivolgiti e scegli Tellatin Ezio. Presso il suo autolavaggio sono molteplici i servizi offerti, tutti hanno in comune l’ottimo rapporto qualità/prezzo. Per maggiori informazioni chiama o se preferisci vieni in sede.',
      list: [
         'Pulitura sedili',
         'Pulitura di divani, poltrone e sedie',
         'Pulitura Tappezzerie'
      ],
      imgs: [
         require('./imgs/servizi/gallery/pelli-verniciatura-sedile.jpg'),
         require('./imgs/servizi/gallery/pelli.jpg'),
         require('./imgs/servizi/gallery/pelli-verniciatura.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/pulitura-sedili.jpg'),
      name: 'Pulitura sedili',
      slug: 'pulitura-sedili',
      content: 'Stai cercando l\'attività affidabile e professionale per la pulitura delle tappezzerie auto? Scegli Tellatin Ezio, un autolavaggio di oltre 1600 mq! Qui auto e moto tornano come nuove. Chiamaci per avere maggiori informazioni.',
      list: [
         'Pulitura sedili',
         'Pulitura di divani, poltrone e sedie',
         'Pulitura Tappezzerie'
      ],
      imgs: [
         require('./imgs/servizi/gallery/sedili-puliti.jpg'),
         require('./imgs/servizi/gallery/sedile-sporco.jpg'),
         require('./imgs/servizi/gallery/sedile-sporco2.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/divani-poltrone-sedie.jpg'),
      name: 'Pulitura divani, poltrone e sedie',
      slug: 'pulitura-divani-poltrone-sedie',
      content: 'Stai cercando l\'attività affidabile e professionale per la pulitura di divani, poltrone e sedie? Scegli Tellatin Ezio, un autolavaggio di oltre 1600 mq! Qui divani, poltrone e sedie tornano come nuove. Chiamaci per avere maggiori informazioni.',
      list: [
         'Pulitura sedili',
         'Pulitura di divani, poltrone e sedie',
         'Pulitura Tappezzerie'
      ],
      imgs: [
         require('./imgs/servizi/gallery/porta-interna-sporca.jpg'),
         require('./imgs/servizi/gallery/sedie.jpg'),
         require('./imgs/servizi/gallery/divano.jpg'),
         require('./imgs/servizi/gallery/divano-cuscini.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/ristrutturazione-interni.jpg'),
      name: 'Ristrutturazione interni',
      slug: 'ristrutturazione-interni',
      content: 'Per il ripristino di spalline sedili moquette, cruscotti, pannelli porta e molto altro della tua auto a Bassano del Grappa, rivolgiti e scegli Tellatin Ezio. Presso il suo autolavaggio sono molteplici i servizi offerti, tutti hanno in comune l\'ottimo rapporto qualità/prezzo. Per maggiori informazioni chiama o se preferisci vieni in sede.',
      list: [
         'Pulitura sedili',
         'Pulitura di divani, poltrone e sedie',
         'Pulitura Tappezzerie'
      ],
      imgs: [
         require('./imgs/servizi/gallery/interni-crepo.jpg'),
         require('./imgs/servizi/gallery/interni-crepo-riparato.jpg'),
         require('./imgs/servizi/gallery/interni.jpg'),
         require('./imgs/servizi/gallery/interni-old.jpg')
      ]
   },
   {
      img: require('./imgs/servizi/sanificazione.jpg'),
      name: 'Sanificazione',
      slug: 'sanificazione',
      content: 'L\'ozonizzazione è un trattamento che permette la completa sanificazione di ogni tipo di ambiente chiuso, con apposite apparecchiature viene generato un gas naturale, l\'ozono, ha proprietà virucide e battericide che permettono la disinfezione. Il trattamento è indirizzato a: Auto, Abitazioni, Uffici, Garage, Capannoni',
      imgs: [
         require('./imgs/servizi/gallery/macchina-ozono.jpg'),
         require('./imgs/servizi/gallery/validazione.jpg'),
         require('./imgs/servizi/gallery/attrezzature.jpg')
      ]
   },
];


export const carsList = [
   {
      name: 'Autocarro Nissan Atleon V.I.TK 56.35',
      slug: 'autocarro-nissan-atleon-vitk-5635',
      price: '15000',
      imgs: [
         require('./imgs/vendita/nissan/autocarro-1.jpeg'),
         require('./imgs/vendita/nissan/autocarro-2.jpeg'),
         require('./imgs/vendita/nissan/autocarro-3.jpeg'),
         require('./imgs/vendita/nissan/autocarro-4.jpeg'),
         require('./imgs/vendita/nissan/autocarro-5.jpeg'),
         require('./imgs/vendita/nissan/autocarro-6.jpeg'),
         require('./imgs/vendita/nissan/autocarro-7.jpeg'),
         require('./imgs/vendita/nissan/autocarro-8.jpeg'),
         require('./imgs/vendita/nissan/autocarro-9.jpeg'),
      ],
      modello: 'Atleon V.I.TK 56.35',
      carrozzeria: 'Cabinato',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: '',
      porte: '',
      chilometraggio: '450000',
      anno: 2003,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: '',
      tipoCambio: '',
      cilindrata: '',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Gasolio',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Bianco',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: '',
      materiale: ''
   },
   {
      name: 'Fiat Panda 4x4 TWINAIR 2018',
      slug: 'fiat-panda-4x4-twinair-2018',
      price: 13900,
      imgs: [
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-1.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-2.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-3.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-4.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-5.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-6.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-7.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2018-8.jpeg'),
      ],
      modello: 'Panda 4x4 TWINAIR',
      carrozzeria: '',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: '',
      porte: '',
      chilometraggio: 71500,
      anno: 2018,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: '85 cavalli',
      tipoCambio: 'Manuale',
      cilindrata: '0,9',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Grigio metallizato',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: '',
      materiale: ''
   },
   {
      name: 'Fiat Panda 4x4 TWINAIR 2019',
      slug: 'fiat-panda-4x4-twinair-2019',
      price: 14900,
      imgs: [
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-1.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-2.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-3.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-4.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-5.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-6.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-7.jpeg'),
         require('./imgs/vendita/fiat-4x4/fiat-twinair-2019-8.jpeg'),
      ],
      modello: 'Panda 4x4 TWINAIR',
      carrozzeria: '',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: '',
      porte: '',
      chilometraggio: 91900,
      anno: 2018,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: '85 cavalli',
      tipoCambio: 'Manuale',
      cilindrata: '0,9',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Bianco',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: '',
      materiale: ''
   },
   {
      name: 'Ford Transit Custom L2 - H2',
      slug: 'ford-transit-custom-l2-h2',
      price: 19900,
      imgs: [
         require('./imgs/vendita/ford-transit/ford-custom-1.jpeg'),
         require('./imgs/vendita/ford-transit/ford-custom-2.jpeg'),
         require('./imgs/vendita/ford-transit/ford-custom-3.jpeg'),
         require('./imgs/vendita/ford-transit/ford-custom-4.jpeg'),
         require('./imgs/vendita/ford-transit/ford-custom-5.jpeg'),
         require('./imgs/vendita/ford-transit/ford-custom-6.jpeg'),
         require('./imgs/vendita/ford-transit/ford-custom-7.jpeg'),
         require('./imgs/vendita/ford-transit/ford-custom-8.jpeg'),
      ],
      modello: 'Transit Custom L2 - H2',
      carrozzeria: '',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: '',
      porte: '',
      chilometraggio: '88,9',
      anno: 2020,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: '130 cavalli',
      tipoCambio: 'Manuale',
      cilindrata: 2000,
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Diesel',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Bianco',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: '',
      materiale: ''
   },
   {
      name: 'Ford Transit Connect L3 - H3',
      slug: 'ford-transit-connect-l3-h3',
      price: 21900,
      imgs: [
         require('./imgs/vendita/ford-transit/ford-connect-1.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-2.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-3.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-4.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-5.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-6.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-7.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-8.jpeg'),
         require('./imgs/vendita/ford-transit/ford-connect-9.jpeg'),
      ],
      modello: 'Transit Connect L3 - H3',
      carrozzeria: '',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: '',
      porte: '',
      chilometraggio: 96000,
      anno: 2021,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: '170 cavalli',
      tipoCambio: 'Manuale',
      cilindrata: 2000,
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Diesel',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Bianco',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: '',
      materiale: ''
   },
   {
      name: 'Mini COUNTRYMAN',
      slug: 'mini-countryman',
      price: 24450,
      imgs: [
         require('./imgs/vendita/mini-countryman/mini-countryman-1.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-2.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-3.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-4.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-5.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-6.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-7.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-8.jpeg'),
         require('./imgs/vendita/mini-countryman/mini-countryman-9.jpeg'),
      ],
      modello: 'COUNTRYMAN',
      carrozzeria: '',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: '',
      porte: '',
      chilometraggio: 79600,
      anno: 2021,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: '116 cavalli',
      tipoCambio: 'Automatico',
      cilindrata: 1500,
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Diesel',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Blu metallizato',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: '',
      materiale: ''
   },
   {
      name: 'Audi A6',
      slug: 'audi-a6',
      price: 6000,
      imgs: [
         require('./imgs/vendita/audi-a6/audi-1.jpg'),
         require('./imgs/vendita/audi-a6/audi-2.jpg'),
         require('./imgs/vendita/audi-a6/audi-3.jpg'),
         require('./imgs/vendita/audi-a6/audi-4.jpg')
      ],
      modello: 'A6',
      carrozzeria: 'Berlina',
      tipoVeicolo: 'Usato (targa oro)',
      trazione: 'Anteriore',
      posti: 5,
      porte: 4,
      chilometraggio: 150000,
      anno: 1998,
      potenza: '',
      tipoCambio: 'Automatico',
      cilindrata: '',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: '',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: '',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: '',
      materiale: ''
   },
   {
      name: 'Volkswagen Maggiolone',
      slug: 'volkswagen-maggiolone',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-1.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-2.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-3.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-4.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-5.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-6.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-7.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-8.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-9.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-10.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-11.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-12.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-13.jpg'),
         require('./imgs/vendita/volkswagen-maggiolone/maggiolone-14.jpg')
      ],
      modello: 'Maggiolone',
      carrozzeria: 'Berlina',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: 2,
      porte: '',
      chilometraggio: 93750,
      anno: 1970,
      revisione: '05/2026',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: 'KW 25 CV 34',
      tipoCambio: 'Manuale',
      cilindrata: 1192,
      marce: 4,
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Blu',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Bianco',
      materiale: ''
   },
   {
      name: 'Ferrari 360 SPIDER F1',
      slug: 'ferrari-360-spider-f1',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/ferrari-360-spider/ferrari-1.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-2.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-3.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-4.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-5.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-6.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-7.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-8.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-9.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-10.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-11.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-12.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-13.jpg'),
         require('./imgs/vendita/ferrari-360-spider/ferrari-14.jpg')
      ],
      modello: 'Spider F1',
      carrozzeria: 'Spider',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: 2,
      porte: '',
      chilometraggio: '',
      anno: 2004,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: '400 PS / 395 HP / 294 KW a 8500 rpm',
      tipoCambio: '',
      cilindrata: 3586,
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Rosso',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Nero',
      materiale: 'Pelle'
   },
   {
      name: 'BMW M3',
      slug: 'bmw-m3',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/bmw-m3/bmw-1.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-2.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-3.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-4.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-5.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-6.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-7.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-8.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-9.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-10.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-11.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-12.jpg'),
         require('./imgs/vendita/bmw-m3/bmw-13.jpg')
      ],
      modello: 'M3',
      carrozzeria: 'Coupè',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: 5,
      porte: 5,
      chilometraggio: '',
      anno: 2020,
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: '',
      proprietari: 1,
      potenza: 'KW 309 CV 420',
      tipoCambio: 'Automatico',
      cilindrata: 3999,
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Nero',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Nero',
      materiale: 'Pelle'
   },
   {
      name: 'Ford Focus',
      slug: 'ford-focus',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/ford-focus/ford-1.jpg'),
         require('./imgs/vendita/ford-focus/ford-2.jpg'),
         require('./imgs/vendita/ford-focus/ford-3.jpg'),
         require('./imgs/vendita/ford-focus/ford-4.jpg'),
         require('./imgs/vendita/ford-focus/ford-5.jpg'),
         require('./imgs/vendita/ford-focus/ford-6.jpg'),
         require('./imgs/vendita/ford-focus/ford-7.jpg'),
         require('./imgs/vendita/ford-focus/ford-8.jpg'),
         require('./imgs/vendita/ford-focus/ford-9.jpg'),
         require('./imgs/vendita/ford-focus/ford-10.jpg'),
         require('./imgs/vendita/ford-focus/ford-11.jpg')
      ],
      modello: 'Focus',
      carrozzeria: '',
      tipoVeicolo: 'Usato',
      trazione: '',
      posti: 5,
      porte: 5,
      chilometraggio: 207765,
      anno: '2002',
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: 'Si',
      proprietari: 1,
      potenza: '66 kW',
      tipoCambio: 'Manuale',
      cilindrata: '',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Diesel',
      cosumoCarburante: '7,2 l/100 km',
      classeEmissioni: '',
      comfort: 'Gancio traino, Porta bici, Box da tetto',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Grigio',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Grigio',
      materiale: 'Stoffa'
   },
   {
      name: 'Audi Q3 35 TDI 150 CV S tronic',
      slug: 'audi-q3-35-tdi-150-cv-s-tronic',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/audi-q3-tdi/audi-1.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-2.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-3.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-4.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-5.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-6.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-7.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-8.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-9.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-10.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-11.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-12.jpg'),
         require('./imgs/vendita/audi-q3-tdi/audi-13.jpg')
      ],
      modello: '35 TDI 150 CV S tronic',
      carrozzeria: 'SUV/Fuoristrada/Pick-up',
      tipoVeicolo: 'Usato',
      trazione: 'Anteriore',
      posti: 5,
      porte: 5,
      chilometraggio: 49600,
      anno: '06/2019',
      revisione: '',
      ultimoTagliando: '',
      tagliandiCertificati: 'Si',
      proprietari: 1,
      potenza: '110 kW (150 CV)',
      tipoCambio: 'Automatico',
      cilindrata: 1968,
      marce: 7,
      cilindri: 4,
      pesoVuoto: 1590,
      carburante: 'Diesel',
      cosumoCarburante: '6 l/100 km',
      classeEmissioni: 'Euro 6',
      comfort: 'Alzacristalli elettrici, Bracciolo, Chiusura centralizzata senza chiave, Climatizzatore, Cruise Control, Specchietti laterali elettrici, Volante in pelle, Volante multifunzione',
      intrattenimento7media: 'Autoradio, Bluetooth, CD, USB, Vivavoce',
      sicurezza: 'ABS, Airbag conducente - laterali - passeggero, Chiusura centralizzata, Controllo automatico trazione, ESP, Immobilizzatore elettronico, Limitatore di velocità',
      colore: 'Arancione',
      coloreSpecifico: 'Arancione',
      tipoVernice: '',
      coloreFinitureInterne: 'Grigio',
      materiale: 'Stoffa'
   },
   {
      name: 'Volkswagen Golf 1600 GL Cabriolet',
      slug: 'volkswagen-golf-1600-gl-cabriolet',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-1.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-2.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-3.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-4.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-5.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-6.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-7.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-8.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-9.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-10.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-11.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-12.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-13.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-14.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-15.jpg'),
         require('./imgs/vendita/volkswagen-golf-1600-cabriolet/golf-16.jpg')
      ],
      modello: 'Golf 1600 GL Cabriolet',
      carrozzeria: 'Cabriolet',
      tipoVeicolo: 'Usato',
      trazione: 'Anteriore',
      posti: 4,
      porte: 2,
      chilometraggio: 100000,
      anno: 1987,
      revisione: '03/2025',
      ultimoTagliando: '01/2023',
      tagliandiCertificati: 'Si',
      proprietari: 1,
      potenza: '54 kW (73 CV)',
      tipoCambio: 'Manuale',
      cilindrata: 1595,
      marce: 5,
      cilindri: 4,
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Rosso',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Grigio',
      materiale: 'Stoffa'
   },
   {
      name: 'Volvo XC 40',
      slug: 'volvo-xc-40',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/volvo-xc-40/volvo-1.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-2.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-3.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-4.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-5.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-6.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-7.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-8.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-9.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-10.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-11.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-12.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-13.jpg'),
         require('./imgs/vendita/volvo-xc-40/volvo-14.jpg')
      ],
      modello: 'XC 40',
      carrozzeria: 'Suv',
      tipoVeicolo: 'Usato',
      trazione: '4x4',
      posti: 5,
      porte: 4,
      chilometraggio: 180000,
      anno: 2019,
      potenza: '',
      tipoCambio: 'Automatico',
      cilindrata: '',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Diesel',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Nero',
      coloreSpecifico: '',
      tipoVernice: 'Metallizato',
      coloreFinitureInterne: 'Grigio',
      materiale: 'Stoffa/Pelle'
   },
   {
      name: 'Citroën C4 Cactus',
      slug: 'citroen-c4-cactus',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/citroen-c4-cactus/citroen-1.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-2.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-3.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-4.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-5.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-6.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-7.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-8.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-9.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-10.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-11.jpg'),
         require('./imgs/vendita/citroen-c4-cactus/citroen-12.jpg')
      ],
      modello: 'C4 Cactus',
      carrozzeria: 'Berlina',
      tipoVeicolo: 'Usato',
      trazione: 'Anteriore',
      posti: 5,
      porte: 4,
      chilometraggio: 78000,
      anno: 2018,
      potenza: '100 KW (136 CV)',
      tipoCambio: 'Manuale',
      cilindrata: '',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Diesel',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Grigio',
      coloreSpecifico: '',
      tipoVernice: 'Metallizato',
      coloreFinitureInterne: 'Grigio',
      materiale: 'Stoffa'
   },
   {
      name: 'Fiat 595 Abarth',
      slug: 'fiat-595-abarth',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/fiat-595-abarth/abarth-1.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-2.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-3.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-4.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-5.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-6.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-7.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-8.jpg'),
         require('./imgs/vendita/fiat-595-abarth/abarth-9.jpg')
      ],
      modello: '595 Abarth',
      carrozzeria: 'Berlina',
      tipoVeicolo: 'Usato',
      trazione: 'Posteriore',
      posti: 4,
      porte: 2,
      chilometraggio: 40000,
      anno: 1962,
      potenza: '',
      tipoCambio: 'Manuale',
      cilindrata: 593.7,
      marce: 4,
      cilindri: 2,
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Rosso',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Nero',
      materiale: 'Pelle'
   },
   {
      name: 'Dune Buggy Scorpion',
      slug: 'dune-buggy-scorpion',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/dune-buggy-scorpion/dune-1.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-2.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-3.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-4.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-5.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-6.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-7.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-8.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-9.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-10.jpg'),
         require('./imgs/vendita/dune-buggy-scorpion/dune-11.jpg')
      ],
      modello: 'Buggy Scorpion',
      carrozzeria: 'Cabriolet',
      tipoVeicolo: 'Usato',
      trazione: 'Posteriore',
      posti: 2,
      porte: 0,
      chilometraggio: 150000,
      anno: 1975,
      potenza: '41CV',
      tipoCambio: 'Manuale',
      cilindrata: 1200,
      marce: 4,
      cilindri: 4,
      pesoVuoto: '',
      carburante: 'Benzina',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Giallo',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Nero',
      materiale: 'Pelle'
   },
   {
      name: 'Mitsubishi L200',
      slug: 'mitsubishi-l200',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/mitsubishi-l200/mitsubishi-1.jpg'),
         require('./imgs/vendita/mitsubishi-l200/mitsubishi-2.jpg'),
         require('./imgs/vendita/mitsubishi-l200/mitsubishi-3.jpg'),
         require('./imgs/vendita/mitsubishi-l200/mitsubishi-4.jpg'),
         require('./imgs/vendita/mitsubishi-l200/mitsubishi-5.jpg'),
         require('./imgs/vendita/mitsubishi-l200/mitsubishi-6.jpg')
      ],
      modello: 'L200',
      carrozzeria: 'Pik-up',
      tipoVeicolo: 'Usato',
      trazione: '4x4',
      posti: 5,
      porte: 4,
      chilometraggio: 244000,
      anno: 2011,
      potenza: '',
      tipoCambio: 'Manuale',
      cilindrata: '',
      marce: '',
      cilindri: '',
      pesoVuoto: '',
      carburante: 'Diesel',
      cosumoCarburante: '',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: '',
      sicurezza: '',
      colore: 'Bianco',
      coloreSpecifico: '',
      tipoVernice: '',
      coloreFinitureInterne: 'Nero',
      materiale: 'Stoffa'
   },
   {
      name: 'Mini Cooper SD',
      slug: 'mini-cooper-sd',
      price: 'VENDUTA',
      imgs: [
         require('./imgs/vendita/mini-cooper-sd/mini-1.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-2.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-3.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-4.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-5.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-6.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-7.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-8.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-9.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-10.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-11.jpg'),
         require('./imgs/vendita/mini-cooper-sd/mini-12.jpg')
      ],
      modello: 'Cooper SD',
      carrozzeria: 'Berlina',
      tipoVeicolo: 'Usato',
      trazione: 'Posteriore',
      posti: 5,
      porte: 4,
      chilometraggio: 190000,
      anno: 2014,
      potenza: '105 KW (142 CV)',
      tipoCambio: 'Automatico',
      cilindrata: 1995,
      marce: 5,
      cilindri: 4,
      pesoVuoto: 1450,
      carburante: 'Diesel',
      cosumoCarburante: '5,7l/100 km (comb) - 7,3l/km (urbano) - 4,8l/km (extraurbano)',
      classeEmissioni: '',
      comfort: '',
      intrattenimento7media: 'Autoradio',
      sicurezza: 'ABS - Airbag - chiusura centralizzata',
      colore: 'Grigio',
      coloreSpecifico: '',
      tipoVernice: 'Metallizato',
      coloreFinitureInterne: 'Bege',
      materiale: 'Pelle'
   }
];