import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Product({ name, slug, img, price, year, km, changeType }) {
   return (
      <article className="pricing">
         <Link to={'/vendita/' + slug}>
            <img src={img} alt={name + " - Vendita auto - Autolavaggio Tellatin Ezio"} />
         </Link>
         <h3 className="mt-2">
            <Link to={'/vendita/' + slug}>{name}</Link>
         </h3>
         <ul className="grid g-1">
            <li className="pricing-feature">
               <FontAwesomeIcon icon="calendar" />
               <p className="m-0">Anno</p>
               <p>{year}</p>
            </li>
            <li className="pricing-feature">
               <FontAwesomeIcon icon="road" />
               <p className="m-0">Chilometri</p>
               <p>{km}</p>
            </li>
            <li className="pricing-feature">
               <FontAwesomeIcon icon="sitemap" />
               <p className="m-0">Cambio</p>
               <p>{changeType}</p>
            </li>
         </ul>

         <h6 className={price == 'VENDUTA' ? 'mt-2 txt-danger' : 'mt-2'}>{typeof price === 'string' ? price : '€ ' + price}</h6>

         <Link to={'/vendita/' + slug} className="btn w-100">Dettagli <FontAwesomeIcon icon="arrow-right" /></Link>
      </article>
   );
}