import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Lightbox({ galleryImgs }) {
   const [clickedImg, setClickedImg] = useState(null),
         [currentIdx, setCurrentIdx] = useState(null),
         imgsLength = galleryImgs.length;

   const handleOpen = (img, idx) => {
      setClickedImg(img);
      setCurrentIdx(idx);
   };
   
   const handleNext = () => {
      if(currentIdx + 1 >= imgsLength) {
         setCurrentIdx(0);
         setClickedImg(galleryImgs[0]);
         return;
      }

      const newIdx = currentIdx + 1;
      setCurrentIdx(newIdx);
      setClickedImg(galleryImgs[newIdx]);
   };
  
   const handlePrev = () => {
      if(currentIdx === 0) {
         const newIdx = imgsLength - 1;
         setCurrentIdx(newIdx);
         setClickedImg(galleryImgs[newIdx]);
         return;
      }
      
      const newIdx = currentIdx - 1;
      setCurrentIdx(newIdx);
      setClickedImg(galleryImgs[newIdx]);
   };

   return (
      <>
         <section className="gallery-container">
            {galleryImgs.map((img, idx) =>
               <img src={img} alt="" key={idx} onClick={()=>handleOpen(img, idx)}/>
            )}
         </section>

         <div className={clickedImg ? "lightbox open" : "lightbox"}>
            <FontAwesomeIcon icon="xmark" onClick={() => setClickedImg(null)} />
                  
            <img src={clickedImg} alt="Autolavaggio Tellatin Ezio" />
                  
            <FontAwesomeIcon icon="arrow-left" onClick={handlePrev} />
            <FontAwesomeIcon icon="arrow-right" onClick={handleNext} />
         </div>
      </>
   );
}