import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function CarouselThumb({ imgs }) {
	const [activeIndex, setActiveIndex] = useState(0),
			handleThumbnailClick = index => setActiveIndex(index),
			handleNextClick = () => setActiveIndex((activeIndex + 1) % imgs.length),
			handlePrevClick = () => setActiveIndex((activeIndex + imgs.length - 1) % imgs.length);

	return (
		<div className="carousel-thumb">
			<div className="carousel-thumb-img">
				<img src={imgs[activeIndex]} alt="" />

				<button onClick={handlePrevClick}><FontAwesomeIcon icon="angle-left" /></button>
				<button onClick={handleNextClick}><FontAwesomeIcon icon="angle-right" /></button>
			</div>

			<div className="carousel-thumb-thumbnails">
				{imgs.map((image, index) => (
					<img
						key={index}
						src={image}
						alt="Auto Usata in vendita - Autolavaggio Tellatin Ezio"
						className={`carousel-thumb-thumbnail ${index === activeIndex ? 'active' : ''}`}
						onClick={() => handleThumbnailClick(index)}
					/>
				))}
			</div>
		</div>
	);
}