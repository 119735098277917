import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useParams  } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { carsList } from '../data';
import useWindowWidth from '../hooks/useWindowWidth';
import PageTitle from '../components/PageTitle';
import CarouselThumb from '../components/CarouselThumb';
import Share from '../components/Share';
import NotFound from './NotFound';


export default function VenditaDettagli() {
   const { carName } = useParams(),
			car = carsList.filter(car => car.slug.includes(carName))[0];

   const isMobile = useWindowWidth() < 880;
         
   if (car) {
      return (
         <>
            <Helmet>
               <title>{car.name} - Vendita auto - Autolavaggio Tellatin Ezio</title>
               <meta name="description" content={car.name + ': scopri la nostra offerta e tutti i dati dell\'automobile.'} />
               <meta name="keywords" content={car.name + ", auto usate, vendita auto, auto usata, auto usate pronta consegna, Autolavaggio Tellatin Ezio, autolavaggio, Tellatin Ezio, servizi autolavaggio, vendita auto, auto usate"} />
            </Helmet>

            <PageTitle pageName={car.name} parentPage="Vendita" />


            <section className="container grid pb-0">
               <div>
                  <CarouselThumb imgs={car.imgs} />

                  <article className="grid gtc-3 border p-2">
                     <div className={isMobile ? 'flex' : ''}>
                        <p className="m-0"><FontAwesomeIcon icon="road" /> Chilometraggio</p>
                        <p>{car.chilometraggio}</p>
                     </div>
                     <div className={isMobile ? 'flex' : ''}>
                        <p className="m-0"><FontAwesomeIcon icon="sitemap" /> Tipo di cambio</p>
                        <p>{car.tipoCambio}</p>
                     </div>
                     <div className={isMobile ? 'flex' : ''}>
                        <p className="m-0"><FontAwesomeIcon icon="calendar" /> Anno</p>
                        <p>{car.anno}</p>
                     </div>
                     {car.carburante &&
                        <div className={isMobile ? 'flex' : ''}>
                           <p className="m-0"><FontAwesomeIcon icon="gas-pump" /> Carburante</p>
                           <p>{car.carburante}</p>
                        </div>
                     }
                     {car.potenza &&
                        <div className={isMobile ? 'flex' : ''}>
                           <p className="m-0"><FontAwesomeIcon icon="gauge-simple-high" /> Potenza</p>
                           <p>{car.potenza}</p>
                        </div>
                     }
                  </article>
               </div>


               <article>
                  <Share title={car.name} />

                  <h2>{car.name}</h2>
                  <h3>Modello: {car.modello}</h3>
                  <h2 className={car.price == 'VENDUTA' ? 'txt-danger' : ''}>{typeof car.price === 'string' ? car.price : '€ ' + car.price}</h2>

                  <div className="flex">
                     <Link to="/contatti" className="btn">Contatta venditore</Link>
                     <a href="tel:0424580568" className="btn">Chiama ora</a>
                  </div>
               </article>
            </section>


            <section className="container">
               <table>
                  {isMobile ?
                     <>
                        <tr>
                           <td colSpan="2"><h6><FontAwesomeIcon icon="car-side" /> Dati di base</h6></td>
                        </tr>
                        {car.carrozzeria &&
                           <tr>
                              <td>Carrozzeria</td><td>{car.carrozzeria}</td>
                           </tr>
                        }
                     </>
                     :
                     <tr>
                        <td rowSpan="5"><h6><FontAwesomeIcon icon="car-side" /> Dati di base</h6></td>
                        {car.carrozzeria &&
                           <>
                              <td>Carrozzeria</td><td>{car.carrozzeria}</td>
                           </>
                        }
                     </tr>
                  }
                  {car.tipoVeicolo &&
                     <tr>
                        <td>Tipo di veicolo</td><td>{car.tipoVeicolo}</td>
                     </tr>
                  }
                  {car.trazione &&
                     <tr>
                        <td>Trazione</td><td>{car.trazione}</td>
                     </tr>
                  }
                  {car.posti &&
                     <tr>
                        <td>Posti</td><td>{car.posti}</td>
                     </tr>
                  }
                  {car.porte &&
                     <tr>
                        <td>Porte</td><td>{car.porte}</td>
                     </tr>
                  }
               </table>

               <table>
                  {isMobile ?
                     <>
                        <tr>
                           <td colSpan="2"><h6><FontAwesomeIcon icon="clock" /> Cronologia veicolo</h6></td>
                        </tr>
                        {car.chilometraggio &&
                           <tr>
                              <td>Chilometraggio</td><td>{car.chilometraggio}</td>
                           </tr>
                        }
                     </>
                     :
                     <tr>
                        <td rowSpan="6"><h6><FontAwesomeIcon icon="clock" /> Cronologia veicolo</h6></td>
                        {car.chilometraggio &&
                           <>
                              <td>Chilometraggio</td><td>{car.chilometraggio}</td>
                           </>
                        }
                     </tr>
                  }
                  {car.anno &&
                     <tr>
                        <td>Anno</td><td>{car.anno}</td>
                     </tr>
                  }
                  {car.revisione &&
                     <tr>
                        <td>Revisione</td><td>{car.revisione}</td>
                     </tr>
                  }
                  {car.ultimoTagliando &&
                     <tr>
                        <td>Ultimo Tagliando</td><td>{car.ultimoTagliando}</td>
                     </tr>
                  }
                  {car.tagliandiCertificati &&
                     <tr>
                        <td>Tagliandi Certificati</td><td>{car.tagliandiCertificati}</td>
                     </tr>
                  }
                  {car.proprietari &&
                     <tr>
                        <td>Proprietari</td><td>{car.proprietari}</td>
                     </tr>
                  }
               </table>

               <table>
                  {isMobile ?
                     <>
                        <tr>
                           <td colSpan="2"><h6><FontAwesomeIcon icon="wrench" /> Dati tecnici</h6></td>
                        </tr>
                        { car.potenza &&
                           <tr>
                              <td>Potenza</td><td>{car.potenza}</td>
                           </tr>
                        }
                     </>
                     :
                     <tr>
                        <td rowSpan="5"><h6><FontAwesomeIcon icon="wrench" /> Dati tecnici</h6></td>
                        { car.potenza &&
                           <>
                              <td>Potenza</td><td>{car.potenza}</td>
                           </>
                        }
                     </tr>
                  }
                  {car.tipoCambio &&
                     <tr>
                        <td>Tipo di cambio</td><td>{car.tipoCambio}</td>
                     </tr>
                  }
                  {car.cilindrata &&
                     <tr>
                        <td>Cilindrata</td><td>{car.cilindrata} cm³</td>
                     </tr>
                  }
                  {car.marce &&
                     <tr>
                        <td>Marce</td><td>{car.marce}</td>
                     </tr>
                  }
                  {car.cilindri &&
                     <tr>
                        <td>Cilindri</td><td>{car.cilindri}</td>
                     </tr>
                  }
                  {car.pesoVuoto &&
                     <tr>
                        <td>Peso a vuoto</td><td>{car.pesoVuoto} kg</td>
                     </tr>
                  }
               </table>

               {(car.carburante || car.cosumoCarburant || car.classeEmissioni) &&
                  <table>
                     {isMobile ?
                        <>
                           <tr>
                              <td colSpan="2"><h6><FontAwesomeIcon icon="leaf" /> Ambiente</h6></td>
                           </tr>
                           {car.carburante &&
                              <tr>
                                 <td>Carburante</td><td>{car.carburante}</td>
                              </tr>
                           }
                        </>
                        :
                        <tr>
                           <td rowSpan="5"><h6><FontAwesomeIcon icon="leaf" /> Ambiente</h6></td>
                           {car.carburante &&
                              <>
                                 <td>Carburante</td><td>{car.carburante}</td>
                              </>
                           }
                        </tr>
                     }
                     {car.cosumoCarburant &&
                        <tr>
                           <td>Consumo di carburante</td><td>{car.cosumoCarburante}</td>
                        </tr>
                     }
                     {car.classeEmissioni &&
                        <tr>
                           <td>Classe Emissioni</td><td>{car.classeEmissioni}</td>
                        </tr>
                     }
                  </table>
               }
               
               {(car.comfort || car.intrattenimento7media || car.sicurezza) &&
                  <table>
                     {isMobile ?
                        <>
                           <tr>
                              <td colSpan="2"><h6><FontAwesomeIcon icon="radio" /> Equipaggiamento</h6></td>
                           </tr>
                           {car.comfort &&
                              <tr>
                                 <td>Comfort</td><td>{car.comfort}</td>
                              </tr>
                           }
                        </>
                        :
                        <tr>
                           <td rowSpan="5"><h6><FontAwesomeIcon icon="radio" /> Equipaggiamento</h6></td>
                           {car.comfort &&
                              <>
                                 <td>Comfort</td><td>{car.comfort}</td>
                              </>
                           }
                        </tr>
                     }
                     {car.intrattenimento7media &&
                     <tr>
                        <td>Intrattenimento 7 media</td><td>{car.intrattenimento7media}</td>
                     </tr>
                     }
                     {car.sicurezza &&
                     <tr>
                        <td>Sicurezza</td><td>{car.sicurezza}</td>
                     </tr>
                     }
                  </table>
               }

               {(car.colore || car.coloreSpecifico || car.tipoVernice  || car.coloreFinitureInterne || car.materiale) &&
                  <table>
                     {isMobile ?
                        <>
                           <tr>
                              <td colSpan="2"><h6><FontAwesomeIcon icon="fill" /> Colori e Interni</h6></td>
                           </tr>
                           {car.colore &&
                              <tr>
                                 <td>Colore</td><td>{car.colore}</td>
                              </tr>
                           }
                        </>
                        :
                        <tr>
                           <td rowSpan="5"><h6><FontAwesomeIcon icon="fill" /> Colori e Interni</h6></td>
                           {car.colore &&
                              <>
                                 <td>Colore</td><td>{car.colore}</td>
                              </>
                           }
                        </tr>
                     }
                     {car.coloreSpecifico &&
                        <tr>
                           <td>Colore Specifico</td><td>{car.coloreSpecifico}</td>
                        </tr>
                     }
                     {car.tipoVernice &&
                        <tr>
                           <td>Tipo di Vernice</td><td>{car.tipoVernice}</td>
                        </tr>
                     }
                     {car.coloreFinitureInterne &&
                        <tr>
                           <td>Colore Finiture interne</td><td>{car.coloreFinitureInterne}</td>
                        </tr>
                     }
                     {car.materiale &&
                        <tr>
                           <td>Materiale</td><td>{car.materiale}</td>
                        </tr>
                     }
                  </table>
               }
            </section>
         </>
      );
   } else {
      return <NotFound />
   }
}