import { Link } from 'react-router-dom';
import { servicesList } from '../data';
import useIsOpen from '../hooks/useIsOpen';
import useWindowWidth from '../hooks/useWindowWidth';
import BookingCTA from './BookingCTA';
import logo from '../imgs/logo.svg';


export default function Header() {
	const [isNavSmOpen, handleIsNavSmOpen] = useIsOpen();
	const [isSubmenuOpen, handleIsSubmenuOpen] = useIsOpen();
	const isMobile = useWindowWidth() < 950;

	return (
		<header className={isNavSmOpen && isMobile ? 'fixed' : null}>
			<div className="container">
				<Link to="/" className="logo">
					<img src={logo} alt="Autolavaggio Tellatin Ezio logo" />
				</Link>

				<button className="sm-nav-toggle" aria-controls="navbar" aria-expanded={isNavSmOpen} onClick={handleIsNavSmOpen}>
					<span className="sr-only">Menu</span>
					<div className="bar1"></div>
					<div className="bar2"></div>
					<div className="bar3"></div>
				</button>
				
				<nav id="navbar" data-visible={isNavSmOpen}>
					<ul>
						<li><Link to="/" onClick={handleIsNavSmOpen}>Home</Link></li>
						<li><Link to="chi-siamo" onClick={handleIsNavSmOpen}>Chi Siamo</Link></li>
						<li className={isSubmenuOpen ? "has-submenu active" : "has-submenu"} onClick={handleIsSubmenuOpen}><a href="#">Servizi</a>
							<ul className="submenu">
								{servicesList.map(service =>
									<li key={service.slug}>
										<Link to={`servizi/${service.slug}`} onClick={()=>{handleIsNavSmOpen();handleIsSubmenuOpen();}}>{service.name}</Link>
									</li>
								)}
							</ul>
						</li>
						<li><Link to="contatti" onClick={handleIsNavSmOpen}>Contatti</Link></li>
						<li><Link to="vendita" className="btn" onClick={handleIsNavSmOpen}>Vendita</Link></li>
					</ul>
				</nav>

				<BookingCTA />
			</div>
		</header>
	);
}