import { Helmet } from 'react-helmet';
import { carsList } from '../data';
import PageTitle from '../components/PageTitle';
import ProductBig from '../components/ProductBig';
import Product from '../components/Product';


export default function Vendita() {
   const promoCar = 'country';
   const carBig = carsList.filter(car => car.slug.includes(promoCar))[0];
   return (
      <>
         <Helmet>
            <title>Vendita - Autolavaggio Tellatin Ezio</title>
            <meta name="description" content="Scopri tutti i nostri Veicoli in vendita: le migliori offerte sulle nostre auto usate in pronta consegna." />
            <meta name="keywords" content="vendita, auto usate, vendita auto, usato garantito, auto usate pronta consegna, Autolavaggio Tellatin Ezio, autolavaggio, Tellatin Ezio, servizi autolavaggio, vendita auto, auto usate" />
         </Helmet>

         <PageTitle pageName="Vendita" />

         <section className="container">

            <ProductBig
               name={carBig.name}
               slug={carBig.slug}
               img={carBig.imgs[0]}
               price={carBig.price}
               year={carBig.anno}
               km={carBig.chilometraggio}
               changeType={carBig.tipoCambio}
            />

            <div className="title mt-5">
               <h2>Veicoli in vendita</h2>
            </div>

            <div className="grid gtc-3">
               {carsList.filter(car => !car.slug.includes(promoCar)).map(car =>
                  <Product
						   key={car.slug}
                     name={car.name}
                     slug={car.slug}
                     img={car.imgs[0]}
                     price={car.price}
                     year={car.anno}
                     km={car.chilometraggio}
                     changeType={car.tipoCambio}
                  />
               )}
            </div>
         </section>
      </>
   );
}