import React, { useState, useEffect } from 'react';
import slide1 from '../imgs/slide-1.webp';
import slide2 from '../imgs/slide-2.webp';
import slide3 from '../imgs/slide-3.webp';


export default function HeroSlider() {
	const [curSlide, setCurSlide] = useState(1);
	const slides = 3;
	const heroSlides = Array.from({ length: slides }, (_, slide) => slide + 1);

	const slideContent = [ '', slide1, slide2, slide3 ];

	useEffect(() => {
		const intervalId = setInterval(() => setCurSlide(slide => (slide >= slides ? 1 : slide + 1)), 8000);
		return () => clearInterval(intervalId);
	}, []);

	const handlePagination = target => setCurSlide(target);

	return (
		<section className="hero-slider pb-0">
			<ul className="hero-bg">
				{heroSlides.map(slide =>
					<li
						key={slide}
						data-slide={slide}
						className="bg-img bg-zoom"
						style={{"--imgUrl": `url('${slideContent[slide]}')`}}
						hidden={slide !== curSlide}
					>
						<div className="container hero-txt">
							<h1 className="txt-white fs-lg"><span className="txt-primary">Ezio</span><br />Tellatin</h1>
							<h5 className="txt-white">Autolavaggio e Rivendita auto</h5>
						</div>
					</li>
				)}
			</ul>

			<ul className="hero-pgs">
				{heroSlides.map(slide =>
					<li
						key={slide}
						data-target={slide}
						className={slide === curSlide ? 'hero-pgs-active' : ''}
						onClick={() => handlePagination(slide)}
					/>
				)}
			</ul>
		</section>
	);
};