import { Link } from 'react-router-dom';
import rulli from '../imgs/rulli.webp';


export default function PageTitle({ pageName, parentPage }) {
   return (
      <section className="page-title bg-img" style={{"--imgUrl": `url('${rulli}')`}} >
         <div className="overlay overlay-dark"></div>
         
         <div className="relative">
            <h1>{pageName}</h1>
            <ul className="inline-list">
               <li><Link to="/">Home</Link></li>
               <li className="txt-light">&#x2F;&#x2F;</li>
               {parentPage &&
               <>
                  <li><Link to={'/' + parentPage.toLowerCase()}>{parentPage}</Link></li>
                  <li className="txt-light">&#x2F;&#x2F;</li>
               </>
               }
               <li>{pageName}</li>
            </ul>
         </div>
      </section>
   );
}