import { Helmet } from 'react-helmet';
import PageTitle from '../components/PageTitle';
import autoAziendale from '../imgs/auto-aziendale.jpg';
import ambiente from '../imgs/ambiente.jpg';

export default function ChiSiamo() {
   return (
      <>
         <Helmet>
            <title>Chi siamo - Autolavaggio Tellatin Ezio</title>
            <meta name="description" content="L'autolavaggio si estende in una area di 1600 mq dove effettuiamo lavaggi per ogni tipo. Inoltre il nostro autolavaggio si è sempre distinto negli anni per il rispetto dell'ambiente." />
            <meta name="keywords" content="furgone, area self service, verniciatura pelli, sottoscocche, auto alluvionate, auto danneggiate, asciugatura, tappezzeria, auto, carrozzeria, perdite di olio, lavaggi motore, lavaggio, ambiente, ecosostenibile, Autolavaggio Tellatin Ezio, autolavaggio, Tellatin Ezio, servizi autolavaggio, vendita auto, auto usate" />
         </Helmet>

         <PageTitle pageName="Chi siamo" />

         <section className="container grid place-center">
            <img src={autoAziendale} alt="Auto Aziendale - Autolavaggio Tellatin Ezio" />

            <div>
               <h2 className="title">Chi <span className="txt-primary">siamo</span></h2>
               <p>L'autolavaggio si estende in una area di 1600 mq, dove abbiamo un ponte sollevatore per alzare le auto sino a 3000 kg, per effettuare i lavaggi motore con perdite di olio, e i sotto scocca della carrozzeria del auto.</p>
               <p>Abbiamo una superfice coperta di 250 mq, dove effettuiamo lavaggi per ogni tipo di tappezzeria, dalle auto (soffitti, sedili, panelli porta, e moquette); ai tappetti e divani di casa, con delle macchine a estrazione.</p>
               <p>Siamo forniti inoltre di una caldaia a carrello per l'asciugatura, che ci dà la possibilità di asciugare la tappezzeria dell'auto anche d'inverno. Inoltre asciughiamo le auto alluvionate o danneggiate dall'acqua.</p>
            </div>

            <div>
               <h2 className="title">Cosa <span className="txt-primary">facciamo</span></h2>
               <p>Le nostre specializzazioni:</p>
               <ul>
                  <li>Lavaggio di tappezzerie (auto, divani, tappeti)</li>
                  <li>Motori, sottoscocche (con ponte sollevatore)</li>
                  <li>Lavaggi manuali serviti</li>
                  <li>Ripristino e verniciatura pelli</li>
               </ul>

               <p>Abbiamo inoltre un'ampia area self, dotata di tre piste a gettoni, un portale a spazzoloni dove puoi lavare anche il tuo furgone, e un'ampia area di aspirazione, con distributore automatico contenente tutto per il fai da te. Il nostro autolavaggio si è sempre distinto negli anni per il rispetto dell'ambiente.</p>
               <p>Abbiamo saputo mantenere negli anni l'impianto di depurazione efficiente, in modo da poter lavare motori e sottoscocche, restando nei parametri previsti. I nostri prodotti, hanno un'alta biodegradabilità non perdendo l'alta qualità pulente.</p>
            </div>

            <img src={ambiente} alt="Rispetto dell'ambiente - Autolavaggio Tellatin Ezio" />
         </section>
      </>
   );
}