import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faArrowLeft, faXmark, faEnvelope, faPhone, faPhoneVolume, faClock, faHome, faMapMarkerAlt, faStar, faCar, faCarSide, faUserFriends, faCalendarAlt, faRoad, faCalendar, faGasPump, faSitemap, faGaugeSimpleHigh, faWrench, faLeaf, faRadio, faFill, faAngleLeft, faAngleRight, faMessage, faBrush, faShower, faBroom, faWind, faChair, faShieldVirus } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import Header from './components/Header';
import Footer from './components/Footer';
import WhatsAppChatBtn from './components/WhatsAppChatBtn';
import ScrollTop from './components/ScrollTop';

import Home from './views/Home';
import ChiSiamo from './views/ChiSiamo';
import Servizi from './views/Servizi';
import Servizio from './views/Servizio';
import Vendita from './views/Vendita';
import VenditaDettagli from './views/VenditaDettagli';
import Contatti from './views/Contatti';
import Cookie from './views/Cookie';
import NotFound from './views/NotFound';

library.add(faArrowRight, faArrowLeft, faXmark, faEnvelope, faMessage, faUserFriends, faCalendarAlt, faHome, faPhone, faPhoneVolume, faClock, faMapMarkerAlt, faStar, faCar, faCarSide, faRoad, faCalendar, faGasPump, faSitemap, faGaugeSimpleHigh, faWrench, faLeaf, faRadio, faFill, faFacebookF, faWhatsapp, faAngleLeft, faAngleRight, faBrush, faChair, faWind, faShower, faBroom, faShieldVirus);


function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location]);

	return (
		<>
			<Header />

			<main>
				<Routes location={location} key={location.key}>
					<Route path="/">
						<Route index element={<Home />} />
						<Route path="chi-siamo" element={<ChiSiamo />} />
						<Route path="servizi">
							<Route index element={<Servizi />} />
							<Route path=":serviceName" element={<Servizio />} />
						</Route>
						<Route path="vendita">
							<Route index element={<Vendita />} />
							<Route path=":carName" element={<VenditaDettagli />} />
						</Route>
						<Route path="contatti" element={<Contatti />} />
						<Route path="cookie-policy" element={<Cookie />} />
						<Route path="/*" element={<NotFound />} />
					</Route>
				</Routes>
			</main>
			
			<Footer />

			<WhatsAppChatBtn />
			<ScrollTop />
		</>
	);
}

export default App;