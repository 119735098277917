import useVisible from '../hooks/useVisible';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ScrollTop() {
   const visible = useVisible(180),
         handleGoTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

   return (
      <button id="goTop" className={visible ? 'visible' : ''}
      onClick={handleGoTop}>
			<FontAwesomeIcon icon="car" />
         <span>vai su</span>
      </button>
   );
}