import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BookingCTA() {
	return (
      <div className="flex align-center">
			<FontAwesomeIcon icon="phone-volume" className="scale-2" />
         <span>
            <h4 className="m-0">Chiama ora:</h4>
            <h3 className="m-0">
               <a href="tel:0424580568">0424 580568</a>
            </h3>
         </span>
      </div>
	);
}