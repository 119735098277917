import { Helmet } from 'react-helmet';
import { useParams  } from 'react-router-dom';
import { servicesList } from '../data';
import PageTitle from '../components/PageTitle';
import Lightbox from '../components/Lightbox';
import NotFound from './NotFound';


export default function Servizio() {
   const { serviceName } = useParams(),
			service = servicesList.filter(service => service.slug.includes(serviceName))[0];
   
   if (service) {
      return (
         <>
            <Helmet>
               <title>{service.name} - Servizi - Autolavaggio Tellatin Ezio</title>
               <meta name="description" content={service.content} />
               <meta name="keywords" content={service.name + ", Autolavaggio Tellatin Ezio, autolavaggio, Tellatin Ezio, servizi autolavaggio, vendita auto, auto usate"} />
            </Helmet>

            <PageTitle pageName={service.name} parentPage="Servizi" />

            <section className="container">
               <div className="grid g-lg place-center">
                  <div>
                     <h2>{service.name}</h2>
                     <h5>Bassano Del Grappa</h5>

                     <p>{service.content}</p>

                     {service.list &&
                        <>
                           <p>Tellatin Ezio si occupa anche di:</p>
                           <ul>
                              {service.list.map(ele =>
                                 <li key={ele}><span className="txt-primary">&bull;</span> {ele}</li>
                              )}
                           </ul>
                        </>
                     }
                  </div>

                  <img src={service.img} alt={service.name} />
               </div>

            </section>

            {service.imgs &&
               <Lightbox galleryImgs={service.imgs} />
            }
         </>
      );
   } else {
      return <NotFound />
   }
}